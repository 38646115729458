import React, { useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";

import Abilities from "./Abilities";

const generateHeroImageURL = (heroName) => {
  if (!heroName) {
    return "";
  }
  const parsedHeroName = heroName.replace("npc_dota_hero_", "");
  return `https://dota-interaction-static-files.s3-us-west-2.amazonaws.com/heroes/${parsedHeroName}.png`;
};

const Hero = ({ heroContainerId }) => {
  const [selectedHeroId, changeSelectedHero] = useState(1);
  const { heroes } = useSelector(({ main }) => ({
    heroes: main.heroes,
  }));

  const selectedHero = heroes.find(({ id }) => id === selectedHeroId);
  return (
    <div className="hero w-1/2">
      <div>Select a Hero</div>
      <div className="text-black">
        <Select
          options={[...heroes]
            .sort((a, b) => (a.localized_name > b.localized_name ? 1 : -1))
            .map(({ id, localized_name }) => ({
              value: id,
              label: localized_name,
            }))}
          onChange={(e) => changeSelectedHero(e.value)}
          value={{ value: selectedHero.id, label: selectedHero.localized_name }}
        />
      </div>
      <div>
        <img
          src={generateHeroImageURL(selectedHero.name)}
          alt={`${selectedHero.localized_name}`}
        />
      </div>
      <div>
        <Abilities
          abilities={selectedHero.abilities}
          heroContainerId={heroContainerId}
        />
      </div>
    </div>
  );
};

export default Hero;
