import { apiGet, apiPost } from "./apiHelpers";

export async function fetchHeroes() {
  return await apiGet("heroes");
}

export async function getInteractions(ability_one, ability_two) {
  return await apiGet("interactions", { ability_one, ability_two });
}

export async function getInteraction(interactionId) {
  return await apiGet(`interactions/${interactionId}`);
}

export async function postVote(vote) {
  return await apiPost("vote", vote);
}

export async function postCreateAccount(account) {
  return await apiPost("create_user", account);
}

export async function postLogin(account) {
  return await apiPost("login", account);
}

export async function postInteraction(interaction) {
  return await apiPost("interactions", interaction);
}
