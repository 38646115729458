import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAbility } from "../reducers/mainReducer";

export const generateAbilityImageURL = (abilityName) => {
  return `https://dota-interaction-static-files.s3-us-west-2.amazonaws.com/abilities/${abilityName}.png`;
};

const buildAbilityGrid = (abilites) => {
  const abilitiyCount = abilites.length;
  if (abilitiyCount === 4) {
    return [abilites.slice(0, 2), abilites.slice(2)];
  }
  const rowCount = Math.ceil(abilitiyCount / 3);
  const out = [];
  for (let i = 0; i <= rowCount; i = i + 1) {
    out.push(abilites.slice(i * 3, i * 3 + 3));
  }
  return out;
};

const Abilities = ({ abilities, heroContainerId }) => {
  const abilityGrid = buildAbilityGrid(abilities);
  const dispatch = useDispatch();
  const selectedAbilityId = useSelector(
    ({ main }) => main[`ability_${heroContainerId}`]
  );

  return (
    <div className="">
      {abilityGrid.map((abilityRow, idx) => (
        <div className="flex flex-column" key={`row_${heroContainerId}_${idx}`}>
          {abilityRow.map((ability) => (
            <div
              className={`flex flex-col items-center p-3 my-2 rounded-xl mx-2 ability-icon border-2 ${
                ability.id === selectedAbilityId.id ? "" : "border-transparent"
              }`}
              key={`ability_${heroContainerId}_${ability.id}`}
              onClick={() =>
                dispatch(
                  setAbility({ ability_index: heroContainerId, ability })
                )
              }
            >
              <div>{ability.localized_name}</div>
              <div>
                {
                  <img
                    alt={ability.name}
                    src={generateAbilityImageURL(ability.name)}
                  />
                }
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Abilities;
