import React from "react";
import Button from "@mui/material/Button";
import { fetchInteractions, sendVote } from "../thunks/thunks";
import { useSelector, useDispatch } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";

const UPVOTE_VALUE = 1;
const DOWNVOTE_VALUE = -1;

const VoteArrow = ({ user_interaction_id, userVoteValue, isUp }) => {
  const { arrowIcon, color, vote_value } = isUp
    ? { arrowIcon: faArrowUp, color: "red", vote_value: UPVOTE_VALUE }
    : { arrowIcon: faArrowDown, color: "blue", vote_value: DOWNVOTE_VALUE };
  const dispatch = useDispatch();
  return (
    <div
      onClick={() =>
        dispatch(
          sendVote({
            ...user_interaction_id,
            vote_value: userVoteValue === vote_value ? 0 : vote_value,
          })
        )
      }
    >
      <FontAwesomeIcon
        icon={arrowIcon}
        color={userVoteValue === vote_value ? color : ""}
      />
    </div>
  );
};

const VoteArrows = ({ id, dispatch, userVote, voteTotal }) => {
  const { user } = useSelector(({ main }) => main);
  const user_interaction_id = {
    user_id: user.id,
    interaction_id: id,
  };
  return (
    <div className="flex flex-col p-2 border-r-2 place-content-center items-center">
      <VoteArrow
        user_interaction_id={user_interaction_id}
        userVoteValue={userVote.vote_value}
        isUp
      />
      <div div="text-center">{voteTotal}</div>
      <VoteArrow
        user_interaction_id={user_interaction_id}
        userVoteValue={userVote.vote_value}
      />
    </div>
  );
};

const InteractionContent = ({
  interaction_text,
  submitted_by,
  submitted_at,
}) => (
  <div className="m-2">
    <div className="text-xs">
      Submitted by: {submitted_by.username} -{" "}
      {new Date(submitted_at * 1000).toLocaleString()}
    </div>
    <div className="my-1">{interaction_text}</div>
  </div>
);

const InteractionBlock = ({ interaction, dispatch }) => (
  <div
    key={interaction.id}
    className="flex flex-row border-solid border-t-2 border-l-2 border-r-2 border-white text-left w-full"
  >
    <VoteArrows
      id={interaction.id}
      dispatch={dispatch}
      userVote={interaction.user_vote || {}}
      voteTotal={interaction.vote_total}
    />
    <InteractionContent {...interaction} />
  </div>
);

const InteractionList = () => {
  const { interactions, interactionsLoading, interactionFirstTimeThrough } =
    useSelector(({ main }) => main);
  if (interactionFirstTimeThrough) {
    return <div>Select two abilities above and press 'Get Interactions'.</div>;
  }

  if (interactionsLoading) {
    return (
      <div className="content-center">
        <ClipLoader color={"white"} loading={interactionsLoading} />
      </div>
    );
  }

  if (!interactions || interactions.length === 0) {
    return <div>No Interactions found.</div>;
  }

  return (
    <div className="border-solid border-b-2 border-white ml-3 mr-3">
      {[...interactions]
        .sort((a, b) => b.vote_total - a.vote_total)
        .map((interaction) => (
          <div key={interaction.id}>
            <InteractionBlock interaction={interaction} />
          </div>
        ))}
    </div>
  );
};

const InteractionContainer = () => {
  const dispatch = useDispatch();

  const { ability_1, ability_2, interactionsLoading } = useSelector(
    ({ main }) => ({
      ability_1: main.ability_1.id,
      ability_2: main.ability_2.id,
      interactionsLoading: main.interactionsLoading,
    })
  );

  const buttonDisabled = !ability_1 || !ability_2 || interactionsLoading;
  return (
    <div className="flex flex-col">
      <div>
        <div style={{ paddingBottom: "15px" }}>
          <Button
            variant={buttonDisabled ? "" : "contained"}
            onClick={() =>
              dispatch(fetchInteractions({ ability_1, ability_2 }))
            }
            disabled={buttonDisabled}
            sx={
              buttonDisabled
                ? {
                    backgroundColor: "gray",
                  }
                : {}
            }
          >
            Get Interactions
          </Button>
        </div>
      </div>
      <InteractionList />
    </div>
  );
};

export default InteractionContainer;
