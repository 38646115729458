import axios from "axios";

const api_key_header = { "X-API-KEY": process.env.REACT_APP_API_KEY };
const baseUrl = process.env.REACT_APP_LOCAL
  ? "http://127.0.0.1:8000"
  : "https://dota-int.herokuapp.com";

const generateHeader = () => ({
  ...api_key_header,
  Authorization: `Bearer ${localStorage.getItem("token")}`,
});

export async function apiGet(endpoint, params = {}, headers = {}) {
  const res = await axios.get(`${baseUrl}/${endpoint}`, {
    headers: { ...generateHeader(), ...headers },
    params,
  });
  return res.data;
}

export async function apiPost(endpoint, payload = {}, headers = {}) {
  const res = await axios
    .post(`${baseUrl}/${endpoint}/`, payload, {
      headers: { ...generateHeader(), ...headers },
    })
    .then((res) => res.data)
    .catch((resp) => {
      return { message: resp.response.data.detail, success: false };
    });
  return res;
}
