import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../thunks/thunks";
import { BasicButton, LinkButton } from "../components/Buttons";

const LogoutButton = ({ logoutHandler, pathname }) => {
  const { url, buttonText } =
    pathname === "/submit-interaction"
      ? { url: "/", buttonText: "View Interactions" }
      : { url: "/submit-interaction", buttonText: "Submit Interaction" };
  return (
    <div className="flex flex-row">
      <LinkButton url={url}>{buttonText}</LinkButton>
      <BasicButton onClick={logoutHandler}>Logout</BasicButton>
    </div>
  );
};

const LoginButtons = () => (
  <div className="flex flex-row">
    <LinkButton url="/create-account">Create Account</LinkButton>
    <LinkButton url="/login">Login</LinkButton>
  </div>
);

const Header = () => {
  const { isLoggedIn } = useSelector(({ main }) => ({
    isLoggedIn: main.user.id,
  }));
  const dispatch = useDispatch();
  const logoutHandler = () => {
    dispatch(logout());
  };
  let { pathname } = useLocation();

  return (
    <div className="header flex justify-between">
      <div className="p2 align-center">
        <Link to="/">Dota Interaction</Link>
      </div>
      {isLoggedIn ? (
        <LogoutButton logoutHandler={logoutHandler} pathname={pathname} />
      ) : (
        <LoginButtons />
      )}
    </div>
  );
};

export default Header;
