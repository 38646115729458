import { Helmet } from "react-helmet";
import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "../App.css";
import Header from "./Header";
import HeroContainer from "./HeroContainer";
import Interactions from "./Interactions";
import CreateAccount from "./Account/CreateAccount";
import Login from "./Account/Login";
import SubmitInteraction from "./SubmitInteraction";
import { loginWithLocalStorage, setHeroes } from "../thunks/thunks";
import { ClipLoader } from "react-spinners";

require("dotenv").config();

const AppInsideHeader = () => {
  const { heroes } = useSelector(({ main }) => ({
    heroes: main.heroes,
  }));

  if (!heroes.length) {
    return (
      <div className="py-5">
        <ClipLoader color={"white"} loading={true} />
        <div>Loading</div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <HeroContainer containerIdLeft={1} containerIdRight={2} />
      <Interactions />
    </React.Fragment>
  );
};

const AppInsideQuery = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loginWithLocalStorage());
    dispatch(setHeroes());
  }, [dispatch]);

  return (
    <div>
      <Header />
      <Routes>
        <Route path="/" element={<AppInsideHeader />} />
        <Route path="/create-account" element={<CreateAccount />} />
        <Route path="/login" element={<Login />} />
        <Route path="/submit-interaction" element={<SubmitInteraction />} />
      </Routes>
    </div>
  );
};

function App() {
  return (
    <div className="background">
      <Helmet>
        <style>{"body { background-color: #1c242d; }"}</style>
      </Helmet>
      <BrowserRouter>
        <div className="App">
          <AppInsideQuery />
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
