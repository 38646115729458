import { createSlice } from "@reduxjs/toolkit";
import {
  fetchInteractions,
  sendCreateAccount,
  sendLogin,
  logout,
  loginWithLocalStorage,
  sendVote,
  setHeroes,
  submitInteraction,
} from "../thunks/thunks";

const initialState = {
  heroes: [],
  ability_1: {},
  ability_2: {},
  ability_submit_1: {},
  ability_submit_2: {},
  interactions: [],
  interactionsLoading: false,
  interactionFirstTimeThrough: true,
  user: { success: false },
  submitInteractionMessage: undefined,
  createAccountResponse: undefined,
  createAccountSuccess: undefined,
};

export const appSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    setAbility: (state, action) => {
      const { ability_index, ability } = action.payload;
      state[`ability_${ability_index}`] = ability;
      return state;
    },
    setUser: (state, action) => {
      const { id, username } = action.payload;
      state["currentUser"] = {
        id,
        username,
      };
      return state;
    },
    clearUser: (state, __) => {
      state["currentUser"] = {
        id: null,
        username: null,
      };
      return state;
    },
    setCreateAccountSuccess: (state, action) => {
      state.createAccountSuccess = action.accountSuccess;
      state.createAccountResponse = undefined;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInteractions.pending, (state, _) => {
      state.interactionsLoading = true;
      state.interactionFirstTimeThrough = false;
      return state;
    });
    builder.addCase(fetchInteractions.fulfilled, (state, action) => {
      state.interactions = action.payload;
      state.interactionsLoading = false;
      state.interactionFirstTimeThrough = false;
      return state;
    });
    builder.addCase(fetchInteractions.rejected, (state, _) => {
      state.interactions = [];
      state.interactionsLoading = false;
      state.interactionFirstTimeThrough = true;
      return state;
    });
    builder.addCase(sendCreateAccount.pending, (state, _) => {
      state.createAccountResponse = "";
      return state;
    });
    builder.addCase(sendCreateAccount.fulfilled, (state, action) => {
      const { success, message } = action.payload;

      const pageMessage = success
        ? "Account Successfully created. Redirecting."
        : message;
      state.createAccountResponse = pageMessage;
      state.createAccountSuccess = success;
      return state;
    });
    builder.addCase(sendLogin.rejected, (state, action) => {
      state.user = { ...action.payload };
      return state;
    });
    builder.addCase(logout.fulfilled, (state, _) => {
      state.user = { success: false };
      return state;
    });
    builder.addCase(sendLogin.fulfilled, (state, action) => {
      state.user = { ...action.payload, success: true };
      return state;
    });
    builder.addCase(loginWithLocalStorage.fulfilled, (state, action) => {
      state.user = { ...action.payload };
      return state;
    });
    builder.addCase(sendVote.fulfilled, (state, action) => {
      const user_vote = action.payload.vote;
      const targetIndex = state.interactions.findIndex(
        ({ id }) => id === user_vote.interaction_id
      );
      if (user_vote && targetIndex > -1) {
        state.interactions[targetIndex] = {
          ...state.interactions[targetIndex],
          user_vote,
        };
      }
      return state;
    });
    builder.addCase(setHeroes.fulfilled, (state, action) => {
      state.heroes = action.payload;
      return state;
    });
    builder.addCase(submitInteraction.fulfilled, (state, _) => {
      state.submitInteractionMessage = "Sucessfully submitted interaction!";
    });
  },
});

export const {
  setAbility,
  setCreateAccountResponse,
  clearCreateAccountResponse,
  setCreateAccountSuccess,
} = appSlice.actions;

export default appSlice.reducer;
