import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

export const BasicButton = ({
  children,
  onClick,
  backgroundColor = "#1c242d",
}) => (
  <div className="px-3">
    <Button
      variant="contained"
      className="px-3"
      onClick={onClick}
      sx={{ backgroundColor }}
    >
      {children}
    </Button>
  </div>
);

export const LinkButton = ({ url, children }) => (
  <Link to={url}>
    <BasicButton> {children} </BasicButton>
  </Link>
);
