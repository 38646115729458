import { Form, Field } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { sendLogin } from "../../thunks/thunks";
import { SingleInput } from "./CreateAccount";
import { Navigate } from "react-router-dom";

const submitForm = async (form, dispatch) => {
  const formData = new FormData();
  for (var key in form) {
    formData.append(key, form[key]);
  }
  dispatch(sendLogin(formData));
};
const Login = () => {
  const dispatch = useDispatch();
  const { message, isSuccess } = useSelector(({ main }) => ({
    message: main.user.message,
    isSuccess: main.user.success,
  }));

  if (isSuccess) {
    return <Navigate to="/" />;
  }

  return (
    <Form
      onSubmit={(form) => submitForm(form, dispatch)}
      validate={() => {}}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <h2>Login</h2>
          <div style={{ alignContent: "center" }}>
            <Field name="username" title="Username" component={SingleInput} />
            <Field
              name="password"
              title="Password"
              password
              component={SingleInput}
            />
          </div>
          <button type="submit">Submit</button>
          <div
            className={isSuccess === false ? "text-red-500" : "text-green-500"}
          >
            {message}
          </div>
        </form>
      )}
    />
  );
};

export default Login;
