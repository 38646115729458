import { Form, Field } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { sendCreateAccount } from "../../thunks/thunks";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import { setCreateAccountSuccess } from "../../reducers/mainReducer";

export const SingleInput = ({ password, title, meta, input }) => (
  <div
    className="flex flex-col"
    style={{ alignItems: "center", color: "black" }}
  >
    <div style={{ color: "white" }}>{title}</div>
    <input type={password && "password"} {...input} />
    {meta.touched && meta.error && <span>{meta.error}</span>}
  </div>
);

const CreateAccount = () => {
  const dispatch = useDispatch();
  const { message, isSuccess } = useSelector(({ main }) => ({
    message: main.createAccountResponse,
    isSuccess: main.createAccountSuccess,
  }));

  let navigate = useNavigate();
  if (isSuccess) {
    setTimeout(() => navigate("/login"), 2000);
  }
  useEffect(() => {
    return () => {
      dispatch(
        setCreateAccountSuccess({
          createAccountSuccess: false,
        })
      );
    };
  });

  return (
    <Form
      onSubmit={(val) => {
        dispatch(sendCreateAccount(val));
      }}
      validate={() => {}}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <h2>Create Account</h2>
          <div style={{ alignContent: "center" }}>
            <Field name="username" title="Username" component={SingleInput} />
            <Field name="email" title="Email" component={SingleInput} />
            <Field
              name="password"
              title="Password"
              password
              component={SingleInput}
            />
          </div>
          <button type="submit">Submit</button>
          <div
            className={isSuccess === false ? "text-red-500" : "text-green-500"}
          >
            {message}
          </div>
        </form>
      )}
    />
  );
};

export default CreateAccount;
