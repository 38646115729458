import Hero from "./Hero";

const HeroContainer = ({ containerIdLeft, containerIdRight }) => {
  return (
    <div className="hero-container">
      <Hero heroContainerId={containerIdLeft} />
      <Hero heroContainerId={containerIdRight} />
    </div>
  );
};

export default HeroContainer;
