import React, { useEffect } from "react";
import HeroContainer from "./HeroContainer";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { BasicButton } from "./Buttons";
import { setHeroes, submitInteraction } from "../thunks/thunks";
import { ClipLoader } from "react-spinners";

const ABILITY_1_SUFFIX = "submit_1";
const ABILITY_2_SUFFIX = "submit_2";

const ABILITY_1_STORE_KEY = `ability_${ABILITY_1_SUFFIX}`;
const ABILITY_2_STORE_KEY = `ability_${ABILITY_2_SUFFIX}`;

const InteractionTextBox = ({
  setInteractionText,
  interactionText,
  disabled,
}) => (
  <div className="pm-3">
    <TextField
      id="submit-interaction"
      variant="filled"
      color="primary"
      disabled={disabled}
      sx={{ backgroundColor: disabled ? "gray" : "white" }}
      onChange={(e) => setInteractionText(e.target.value)}
      value={interactionText}
    />
  </div>
);

const SubmitInteractionContainer = () => {
  const { ability_one, ability_two, user_id } = useSelector(({ main }) => ({
    ability_one: main[ABILITY_1_STORE_KEY],
    ability_two: main[ABILITY_2_STORE_KEY],
    user_id: main.user.id,
  }));
  const { heroes } = useSelector(({ main }) => ({
    heroes: main.heroes,
  }));
  const [interactionText, setInteractionText] = useState("");
  const bothAbilitiesNotSelected = !(ability_one.id && ability_two.id);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!heroes.length) {
      dispatch(setHeroes());
    }
  });

  if (!heroes.length) {
    return (
      <div className="content-center justify-center py-20">
        <ClipLoader color={"white"} loading />
      </div>
    );
  }

  return (
    <div>
      <HeroContainer
        containerIdLeft={ABILITY_1_SUFFIX}
        containerIdRight={ABILITY_2_SUFFIX}
      />
      {(bothAbilitiesNotSelected && (
        <div>Choose two abilities to submit an interaction.</div>
      )) || <div style={{ opacity: "0" }}>placeholder</div>}
      <InteractionTextBox
        disabled={bothAbilitiesNotSelected}
        setInteractionText={setInteractionText}
        interactionText={interactionText}
      />
      <BasicButton
        backgroundColor="#2b3744"
        onClick={() =>
          dispatch(
            submitInteraction({
              ability_one: ability_one.id,
              ability_two: ability_two.id,
              interaction_text: interactionText,
              user_id,
            })
          )
        }
      >
        Submit
      </BasicButton>
    </div>
  );
};

// {
//     "ability_one": 0,
//     "ability_two": 0,
//     "interaction_text": "string",
//     "user_id": 0
// }
export default SubmitInteractionContainer;
