import jwt from "jwt-decode";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getInteractions,
  postVote,
  postCreateAccount,
  postLogin,
  fetchHeroes,
  getInteraction,
  postInteraction,
} from "../api/api";

export const fetchInteractions = createAsyncThunk(
  "getInteractions",
  async (abilities, _thunkAPI) => {
    const { ability_1, ability_2 } = abilities;
    return await getInteractions(ability_1, ability_2);
  }
);

export const fetchInteraction = createAsyncThunk(
  "getInteraction",
  async (interactionId, _thunkAPI) => {
    return await getInteraction(interactionId);
  }
);

export const sendVote = createAsyncThunk(
  "postVote",
  async (vote, { rejectWithValue, dispatch }) => {
    const res = await postVote(vote);
    if (!res.success) {
      return rejectWithValue(res);
    }
    dispatch(fetchInteraction(vote.interaction_id));
    return res;
  }
);

export const sendCreateAccount = createAsyncThunk(
  "postCreateAccount",
  async (account, _thunkAPI) => {
    return await postCreateAccount(account);
  }
);

export const sendLogin = createAsyncThunk(
  "postLogin",
  async (account, { rejectWithValue, dispatch, getState }) => {
    localStorage.removeItem("token");
    const res = await postLogin(account);
    if (!res.access_token) {
      return rejectWithValue(res);
    }
    try {
      const { access_token } = res;
      localStorage.setItem("token", access_token);
      const parsedToken = jwt(access_token);
      const {
        main: { ability_1, ability_2 },
      } = getState();
      if (ability_1.id && ability_2.id) {
        dispatch(
          fetchInteractions({
            ability_1: ability_1.id,
            ability_2: ability_2.id,
          })
        );
      }
      return parsedToken;
    } catch {
      return { success: false, message: "Error Logging in." };
    }
  }
);

export const logout = createAsyncThunk(
  "logout",
  (_, { dispatch, getState }) => {
    localStorage.removeItem("token");
    const {
      main: { ability_one: ability_1, ability_two: ability_2 },
    } = getState();

    if (ability_1 && ability_2) {
      dispatch(fetchInteractions(ability_1.id, ability_2.id));
    }
    return {};
  }
);

export const loginWithLocalStorage = createAsyncThunk(
  "loginWithLocalStorage",
  () => {
    const localToken = localStorage.getItem("token");
    if (!localToken) {
      return { success: false };
    }
    const res = jwt(localToken);
    if (res.exp < Date.now() / 1000) {
      localStorage.removeItem("token");
    }
    return res;
  }
);

export const setHeroes = createAsyncThunk("fetchHeroes", async () => {
  return await fetchHeroes();
});

export const submitInteraction = createAsyncThunk(
  "postInteractions",
  async (interaction, { rejectWithValue, _ }) => {
    const res = await postInteraction(interaction);
    if (!res.success) {
      return rejectWithValue(res);
    }
  }
);
